<template>
  <section class="coovally" :style="{ marginTop: marginTop }">
    <!-- <div class="activity" v-show="isacticity">
      <img
        src="../../../assets/zh/images/shouye_huodong@2x.png"
        @click="RouteActicity()"
        alt=""
      />
      <div class="close" @click="close()">
        <span style="color: white" class="iconfont iconToast_closed"></span>
      </div>
    </div> -->
    <section class="banner">
      <figure class="buttons">
        <div class="button">
          <el-button class="checked" style="width: 136px">{{
            $t("PC.Product.CooVallyButton.deaa52")
          }}</el-button>
          <el-button
            class="unchecked"
            style="width: 136px"
            @click="RoutePush('/CooVally')"
            >{{ $t("PC.Product.CooVallyButton.2446d2") }}</el-button
          >
          <el-button class="unchecked" @click="RoutePush('/AIShopNew')">{{
            $t("PC.Product.CooVallyButton.fad28a")
          }}</el-button>
          <!-- <el-button class="unchecked" @click="RoutePush('/ApplyTrial')">{{
            $t("PC.Product.CooVallyButton.ed4a49")
          }}</el-button> -->
        </div>
      </figure>

      <figure class="firstItem">
        <span>{{ $t("PC.Product.CooVally.67ecea") }}</span>
        <p>
          {{ $t("PC.Product.CooVally.8887a5") }}
        </p>
      </figure>
      <figure class="mainImg">
        <img
          src="../../../assets/images/CooVally/shouye_jiemian@2x.png"
          style="width: 100%; height: 100%"
          alt=""
        />
      </figure>

      <figure class="reason">
        <p>{{ $t("PC.Product.CooVally.a7ad3a") }}</p>
        <span class="title">{{ $t("PC.Product.CooVally.7c3d01") }} </span>
        <span class="title" style="margin-top: 0px">
          {{ $t("PC.Product.CooVally.de1f46") }}</span
        >
        <div class="reasons">
          <div class="reason1">
            <div class="reason1_text">
              <div class="icon">
                <img
                  src="../../../assets/images/CooVally/shouye_liucheng@2x.png"
                  alt=""
                />
              </div>
              <div class="reasons_title">
                <span>{{ $t("PC.Product.CooVally.8db978") }}</span>
              </div>
              <div class="desc">
                <span>{{ $t("PC.Product.CooVally.3cadbc") }}</span>
              </div>
            </div>
          </div>

          <div class="reason2">
            <div class="reason2_text">
              <div class="icon">
                <img
                  src="../../../assets/images/CooVally/shouye_sousuo@2x.png"
                  alt=""
                />
              </div>
              <div class="reasons_title">
                <span>{{ $t("PC.Product.CooVally.a7eb83") }}</span>
              </div>
            </div>
          </div>

          <div class="reason3">
            <div class="reason3_text">
              <div class="icon">
                <img
                  src="../../../assets/images/CooVally/shouye_moxing@2x.png"
                  alt=""
                />
              </div>
              <div class="reasons_title">
                <span>{{ $t("PC.Product.CooVally.dd4a7a") }}</span>
              </div>
            </div>
          </div>

          <div class="reason4">
            <div class="reason4_text">
              <div class="icon">
                <img
                  src="../../../assets/images/CooVally/shouye_zhuanhuan@2x.png"
                  alt=""
                />
              </div>
              <div class="reasons_title">
                <span>{{ $t("PC.Product.CooVally.041dbb") }}</span>
              </div>
            </div>
          </div>

          <div class="reason5">
            <div class="reason5_text">
              <div class="icon">
                <img
                  src="../../../assets/images/CooVally/shouye_biaozhu@2x.png"
                  alt=""
                />
              </div>
              <div class="reasons_title">
                <span>{{ $t("PC.Product.CooVally.9d3e7d") }}</span>
              </div>
            </div>
          </div>
        </div>
      </figure>

      <figure class="modular">
        <p>{{ $t("PC.Product.CooVally.61b160") }}</p>
        <p>{{ $t("PC.Product.CooVally.13b263") }}</p>

        <div class="boxWrap">
          <div class="boxS">
            <div class="caseBox" v-for="(item, index) in caseBox" :key="index">
              <div class="iconWrap">
                <img :src="item.src" alt="" />
              </div>
              <p>{{ item.title }}</p>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>

        <div class="boxWrap">
          <div class="boxS">
            <div class="caseBox" v-for="(item, index) in caseBox2" :key="index">
              <div class="iconWrap">
                <img :src="item.src" alt="" />
              </div>
              <p>{{ item.title }}</p>
              <p>{{ item.content }}</p>
            </div>
          </div>
        </div>
      </figure>

      <figure class="layout">
        <div class="wordWrap">
          <p>{{ $t("PC.Product.CooVally.248976") }}</p>
          <el-button @click="GoCooVally()">{{
            $t("PC.Product.CooVallyButton.ed4a49")
          }}</el-button>
        </div>
        <img src="../../../assets/images/CooVally/shouye_bg4@2x.png" alt="" />
      </figure>
      <FooterC></FooterC>
    </section>
  </section>
</template>

<script>
import FooterC from "../components/FooterC.vue";
export default {
  name: "Home",
  components: {
    FooterC,
  },

  data() {
    return {
      date: "",
      ContactUsVisible: false,
      caseBox: [
        {
          src: require("/src/assets/images/CooVally/shouye_computer@2x.png"),
          title: this.$t("PC.Product.CooVally.ec3153"),
          content: this.$t("PC.Product.CooVally.7614d9"),
        },
        {
          src: require("/src/assets/images/CooVally/icon_gongshi@2x.png"),
          title: this.$t("PC.Product.CooVally.1a8067"),
          content: this.$t("PC.Product.CooVally.1d3cbc"),
        },
        {
          src: require("/src/assets/images/CooVally/icon_jingli@2x.png"),
          title: this.$t("PC.Product.CooVally.4566bf"),
          content: this.$t("PC.Product.CooVally.ed6684"),
        },
      ],

      caseBox2: [
        {
          src: require("/src/assets/images/CooVally/shouye_ai@2x.png"),
          title: this.$t("PC.Product.CooVally.90cff7"),
          content: this.$t("PC.Product.CooVally.95f9a7"),
        },
        {
          src: require("/src/assets/images/CooVally/shouye_ruanjian@2x.png"),
          title: this.$t("PC.Product.CooVally.5d0980"),
          content: this.$t("PC.Product.CooVally.4219d5"),
        },
        {
          src: require("/src/assets/images/CooVally/shouye_yingjian@2x.png"),
          title: this.$t("PC.Product.CooVally.a6a58e"),
          content: this.$t("PC.Product.CooVally.62676d"),
        },
        {
          src: require("/src/assets/images/CooVally/shouye_ziyou@2x.png"),
          title: this.$t("PC.Product.CooVally.dcc95c"),
          content: this.$t("PC.Product.CooVally.95f74f"),
        },
      ],
      marginTop: "",
      isacticity: true,
    };
  },
  created() {
    this.marginTop = "70px";
  },
  mounted() {
    this.date = new Date().getFullYear() + "";
  },
  methods: {
    ContactUs() {
      this.ContactUsVisible = true;
    },
    routerGo(routeName) {
      const { href } = this.$router.resolve({
        name: routeName,
      });
      window.open(href);
    },
    newOpen(url) {
      window.open(url);
    },
    RoutePush(url) {
      this.$router.push({
        path: url,
      });
    },

    GoCooVally() {
      let url = Global.COOVALLY_URL;
      window.open(url, "_blank");
    },
    RouteActicity() {
      let routeData = this.$router.resolve({ path: "/Acticity" });
      window.open(routeData.href, "_blank");
    },

    close() {
      this.marginTop = "70px";
      this.isacticity = false;
    },
  },
};
</script>

<style lang="scss" scoped>
/deep/.el-popup-parent--hidden {
  overflow: hidden;
}

.coovally {
  margin-top: 85px;
  word-break: break-word !important;

  .activity {
    position: fixed;
    top: 70px;
    left: 0px;
    z-index: 9;
    img {
      width: 100%;
    }
    img:hover {
      cursor: pointer;
    }

    .close {
      z-index: 99;
      position: fixed;
      left: 98%;
      top: 12vh;
      // background: red;
      widows: 10px;
      height: 20px;
    }
  }

  //首屏
  .banner {
    position: relative;
    height: 100%;
    background-image: url("../../../assets/images/CooVally/bg2.png");
    background-size: 100% 20%;
    background-repeat: no-repeat;
    width: 100%;
    // margin-top: -16px;
    .buttons {
      width: 100%;
      display: flex;
      justify-content: center;
      .button {
        display: flex;
        justify-content: space-between;
        width: 558px;
        padding-top: 48px;
        .checked {
          width: 180px;
          height: 60px;
          background-color: #4568ee;
          color: #fafcff;
          border-radius: 4px;
          padding: 0 0 0 0;
          font-size: 20px;
        }
        .checked:hover {
          width: 180px;
          height: 60px;
          background: #4e76f7;
          // border-radius: 26px;
          opacity: 0.8;
        }

        .unchecked {
          width: 180px;
          height: 60px;
          background-color: #4568ee1a;
          color: #4568ee;
          border-radius: 4px;
          padding: 0 0 0 0;
          font-size: 20px;
        }
        .unchecked:hover {
          width: 180px;
          height: 60px;
          background: #4568ee;
          color: #fafcff;
          // border-radius: 26px;
          // opacity: 0.8;
        }
      }
    }
    .firstItem {
      margin-top: 54px;
      position: relative;
      text-align: center;
      span {
        // width: 1005px;
        height: 64px;
        font-size: 47px;
        font-family: PingFangSC-Semibold, PingFang SC;
        font-weight: 600;
        color: #2d3242;
        line-height: 64px;
      }
      p:nth-of-type(1) {
        font-size: 20px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #2d3242;
        line-height: 24px;
        padding-top: 36px;
      }
    }
    .mainImg {
      position: relative;
      margin: 0 auto;
      margin-top: -128px;
      width: 1181.8px;
      height: 914.85px;
    }
    //理由
    .reason {
      position: relative;
      text-align: center;
      height: 750px;
      width: 100%;
      margin-top: -56px;
      // background-color: black;
      p {
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1f232e;
        line-height: 50px;
        // padding-top: 46px;
      }

      .title {
        display: block;
        font-size: 18px;
        font-family: PingFangSC-Regular, PingFang SC;
        font-weight: 400;
        color: #51565d;
        line-height: 32px;
        margin-top: 13px;
        // padding-bottom: 56px;
      }

      .reasons {
        position: relative;
        width: 1200px;
        height: 522px;
        // background-color: green;
        overflow: hidden;
        margin: 0 auto;

        margin-top: 38px;
        img {
          width: 44px;
          height: 44px;
        }
        .reason1 {
          position: absolute;
          width: 653px;
          height: 251px;
          background: #fafcff;
          background-image: url("../../../assets/images/CooVally/bg3@2x.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .reason1_text {
            position: absolute;
            top: 32px;
            left: 32px;
            width: 550px;
            height: 150px;
            .icon {
              position: absolute;
            }
            .reasons_title {
              position: absolute;
              top: 64px;
              height: 36px;
              font-size: 22px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 36px;
            }
            .desc {
              position: absolute;
              text-align: left;
              top: 106px;
              height: 27px;
              font-size: 16px;
              font-family: PingFangSC-Regular, PingFang SC;
              font-weight: 400;
              color: #ffffffa6;
              line-height: 27px;
            }
          }
        }

        .reason2 {
          position: absolute;
          left: 673px;
          width: 526px;
          height: 251px;
          background: #fafcff;
          background-image: url("../../../assets/images/CooVally/bg4@2x.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .reason2_text {
            position: absolute;
            top: 32px;
            left: 32px;
            width: 450px;
            height: 72px;
            text-align: left;
            .icon {
              position: absolute;
            }
            .reasons_title {
              position: absolute;
              top: 64px;
              height: 36px;
              font-size: 22px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 36px;
            }
          }
        }

        .reason3 {
          position: absolute;
          top: 271px;
          width: 387px;
          height: 251px;
          background: #fafcff;
          background-image: url("../../../assets/images/CooVally/bg5@2x.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .reason3_text {
            position: absolute;
            top: 32px;
            left: 32px;
            width: 340px;
            height: 72px;
            text-align: left;
            .icon {
              position: absolute;
            }
            .reasons_title {
              position: absolute;
              top: 64px;
              height: 36px;
              font-size: 22px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 36px;
            }
          }
        }

        .reason4 {
          position: absolute;
          top: 271px;
          left: 407px;
          width: 387px;
          height: 251px;
          background: #fafcff;
          background-image: url("../../../assets/images/CooVally/bg6@2x.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .reason4_text {
            position: absolute;
            top: 32px;
            left: 32px;
            width: 330px;
            height: 72px;
            text-align: left;
            .icon {
              position: absolute;
            }
            .reasons_title {
              position: absolute;
              top: 64px;
              height: 36px;
              font-size: 22px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 36px;
            }
          }
        }

        .reason5 {
          position: absolute;
          top: 271px;
          left: 814px;
          width: 387px;
          height: 251px;
          background: #fafcff;
          background-image: url("../../../assets/images/CooVally/bg7@2x.png");
          background-size: 100% 100%;
          background-repeat: no-repeat;
          .reason5_text {
            position: absolute;
            top: 32px;
            left: 32px;
            width: 350px;
            height: 72px;
            text-align: left;
            .icon {
              position: absolute;
            }
            .reasons_title {
              position: absolute;
              top: 64px;
              height: 36px;
              font-size: 22px;
              font-family: PingFangSC-Medium, PingFang SC;
              font-weight: 500;
              color: #ffffff;
              line-height: 36px;
            }
          }
        }
      }
    }

    //用户群体
    .modular {
      width: 100%;
      height: 690px;
      // background-image: url("../../assets/slices/homesc_bg.png");
      text-align: center;
      background-color: #fafcff;

      p:nth-of-type(1) {
        font-size: 36px;
        font-family: PingFangSC-Medium, PingFang SC;
        font-weight: 500;
        color: #1f232e;
        line-height: 50px;
        padding-top: 10px;
      }

      p:nth-of-type(2) {
        font-family: PingFangSC-Regular, PingFang SC;
        margin-top: 20px;
        font-size: 18px;
        font-weight: 400;
        color: #51565d;
        line-height: 25px;
      }

      .boxWrap {
        width: 100%;
        display: flex;
        text-align: center;
        align-items: center;
        flex-direction: row;
        .boxS {
          display: flex;
          justify-content: center;
          margin: 0 auto;

          .caseBox {
            margin: 0 50px 0 50px;
            width: 220px;
            height: 240px;
            padding-top: 32px;
            display: flex;
            flex-direction: column;
            align-items: center;
            position: relative;

            p:nth-of-type(1) {
              // margin: 0;
              margin: -40px 0 12px 0;
              font-size: 22px;
              font-family: PingFangSC-Semibold, PingFang SC;
              font-weight: 600;
              color: #33383e;
              line-height: 33px;
            }

            p:nth-of-type(2) {
              font-size: 14px;
              color: #636c78;
              line-height: 22px;
              margin: 0;
            }

            p:nth-of-type(3) {
              font-size: 14px;
              color: #636c78;
              line-height: 22px;
              margin: 0;
            }

            .iconWrap {
              // width: 194px;
              // height: 194px;
              margin: 0;

              img {
                width: 162px;
                height: 148 px;
              }
            }
          }
        }
      }
    }
    // 联系我们
    .layout {
      width: 100%;
      display: flex;
      justify-content: center;
      height: 360px;
      text-align: center;

      .wordWrap {
        height: 360px;
        position: absolute;
        display: flex;
        justify-content: center;

        p {
          font-size: 36px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #ffffff;
          line-height: 50px;
          padding-top: 114px;
        }

        .el-button {
          width: 154px;
          height: 50px;
          background: #fff;
          border-radius: 26px;
          position: absolute;
          font-size: 16px;
          font-family: PingFangSC-Medium, PingFang SC;
          font-weight: 500;
          color: #4e76f7;
          line-height: 22px;
          margin-top: 200px;

          &:hover {
            width: 154px;
            height: 50px;
            background: #ffffff;
            border-radius: 26px;
            opacity: 0.8;
          }
        }
      }

      img {
        width: 100%;
        height: 360px;
        background: #3367f6;
      }
    }
  }
}
</style>
